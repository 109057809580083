import { useEffect, useMemo } from 'react';
import {
  AddressAutofillOptions,
  AddressAutofillCore
} from '@mapbox/search-js-core';

const DEFAULTS = AddressAutofillCore.defaults;

/**
 * A React hook that returns a {@link AddressAutofillCore} instance.
 *
 * @param {AddressAutofillOptions} options
 * @param {string} options.accessToken
 * @see {@link AddressAutofillCore}
 */
export function useAddressAutofillCore(
  options: Partial<{ accessToken: string } & AddressAutofillOptions>
): AddressAutofillCore {
  const autofill = useMemo(() => {
    return new AddressAutofillCore();
  }, []);

  useEffect(() => {
    const { accessToken, ...restOptions } = options;
    autofill.accessToken = accessToken;
    autofill.defaults = {
      ...DEFAULTS,
      ...restOptions
    };
  }, [options]);

  return autofill;
}
