import { useEffect, useMemo } from 'react';
import { SearchBoxCore, SearchBoxOptions } from '@mapbox/search-js-core';

const DEFAULTS = SearchBoxCore.defaults;

/**
 * A React hook that returns a {@link SearchBoxCore} instance.
 *
 * @param {SearchBoxOptions} options
 * @param {string} options.accessToken
 * @see {@link SearchBoxCore}
 */
export function useSearchBoxCore(
  options: Partial<{ accessToken: string } & SearchBoxOptions>
): SearchBoxCore {
  const search = useMemo(() => {
    return new SearchBoxCore();
  }, []);

  useEffect(() => {
    const { accessToken, ...restOptions } = options;
    search.accessToken = accessToken;
    search.defaults = {
      ...DEFAULTS,
      ...restOptions
    };
  }, [options]);

  return search;
}
