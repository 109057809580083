/* eslint-disable @typescript-eslint/no-namespace */

import React, { useRef, useEffect, useImperativeHandle } from 'react';

import {
  SearchBoxOptions,
  SearchBoxSuggestionResponse,
  SearchBoxRetrieveResponse
} from '@mapbox/search-js-core';
import {
  MapboxSearchBox,
  Theme,
  MapboxHTMLEvent,
  PopoverOptions
} from '@mapbox/search-js-web';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      'mapbox-search-box': any;
    }
  }
}

/**
 * @typedef SearchBoxRefType
 */
export interface SearchBoxRefType {
  /**
   * @see {@link MapboxSearchBox#focus}
   */
  focus: typeof MapboxSearchBox.prototype.focus;
}

/**
 * @typedef SearchBoxProps
 */
export interface SearchBoxProps {
  /**
   * The [Mapbox access token](https://docs.mapbox.com/help/glossary/access-token/) to use for all requests.
   */
  accessToken: string;
  /**
   * Options to pass to the underlying {@link SearchBoxCore} interface.
   * @example
   * ```typescript
   * <SearchBox options={{
   *  language: 'en',
   *  country: 'US',
   * }}>
   * ```
   */
  options?: Partial<SearchBoxOptions>;
  /**
   * The {@link Theme} to use for styling the search box.
   * @example
   * ```typescript
   * <SearchBox theme={{
   *   variables: {
   *     colorPrimary: 'myBrandRed'
   *   }
   * }}>
   * ```
   */
  theme?: Theme;
  /**
   * The {@link PopoverOptions} to define popover positioning.
   * @example
   * ```typescript
   * <SearchBox popoverOptions={{
   *   placement: 'top-start',
   *   flip: true,
   *   offset: 5
   * }}>
   * ```
   */
  popoverOptions?: Partial<PopoverOptions>;
  /**
   * If specified, the map will be centered on the retrieved suggestion.
   */
  map?: mapboxgl.Map;
  /**
   * Value to display in the search box.
   */
  value?: string;
  /**
   * Callback for when the value changes.
   */
  onChange?: (value: string) => void;
  /**
   * Fired when the user is typing in the input and provides a list of suggestions.
   * The underlying response from {@link SearchBoxCore} is passed.
   */
  onSuggest?: (res: SearchBoxSuggestionResponse) => void;
  /**
   * Fired when {@link SearchBoxCore} has errored providing a list of suggestions.
   * The underlying error is passed.
   */
  onSuggestError?: (error: Error) => void;
  /**
   * Fired when the user has selected a suggestion.
   * The underlying response from {@link SearchBoxCore} is passed.
   */
  onRetrieve?: (res: SearchBoxRetrieveResponse) => void;

  /**
   * A callback providing the opportunity to validate and/or manipulate the input text before it triggers a search, for example by using a regular expression.
   * If a truthy string value is returned, it will be passed into the underlying search API. If `null`, `undefined` or empty string  is returned, no search request will be performed.
   */
  interceptSearch?: (value: string) => string;
}

/**
 * `<SearchBox>` is a React component that provides an interactive search box,
 * powered by the Mapbox Search Box API.
 *
 * To use this element, you must have a [Mapbox access token](https://www.mapbox.com/help/create-api-access-token/).
 *
 * Internally, this wraps the [`<mapbox-search-box>`](https://docs.mapbox.com/mapbox-search-js/api/web/search/#mapboxsearchbox) element.
 *
 * @function SearchBox
 * @param {SearchBoxProps} props
 * @example
 * ```typescript
 * export function Component() {
 *   const [value, setValue] = React.useState('');
 *   return (
 *     <form>
 *       <SearchBox accessToken={<your access token here>} />
 *     </form>
 *   );
 * }
 * ```
 */
export const SearchBox = React.forwardRef(
  (props: SearchBoxProps, refProp): React.ReactElement => {
    const {
      accessToken,
      options,
      theme,
      popoverOptions,
      map,
      value,
      onChange,
      onSuggest,
      onSuggestError,
      onRetrieve,
      interceptSearch
    } = props;
    const ref = useRef<MapboxSearchBox>();

    useImperativeHandle(refProp, () => ({
      focus: () => {
        if (ref.current) return ref.current.focus();
        throw new Error('SearchBox is not mounted');
      }
    }));

    // Update options.
    useEffect(() => {
      if (ref.current) ref.current.options = options;
    }, [ref.current, options]);

    // Update intercept search.
    useEffect(() => {
      if (ref.current) ref.current.interceptSearch = interceptSearch;
    }, [ref.current, options]);

    // Update theme.
    useEffect(() => {
      if (ref.current) ref.current.theme = theme;
    }, [ref.current, theme]);

    // Update popoverOptions
    useEffect(() => {
      if (ref.current) ref.current.popoverOptions = popoverOptions;
    }, [ref.current, popoverOptions]);

    // Update value.
    useEffect(() => {
      if (ref.current) ref.current.value = value;
    }, [ref.current, value]);

    // Update map.
    useEffect(() => {
      const node = ref.current;
      if (!node) return;

      node.bindMap(map);
      return () => {
        node.unbindMap();
      };
    }, [ref.current, map]);

    // Update onSuggest.
    useEffect(() => {
      const node = ref.current;
      if (!node) return;

      if (!onSuggest) return;

      const fn = (e: MapboxHTMLEvent<SearchBoxSuggestionResponse>) =>
        onSuggest(e.detail);

      node.addEventListener('suggest', fn);
      return () => {
        node.removeEventListener('suggest', fn);
      };
    }, [ref.current, onSuggest]);

    // Update onSuggestError.
    useEffect(() => {
      const node = ref.current;
      if (!node) return;

      if (!onSuggestError) return;

      const fn = (e: MapboxHTMLEvent<Error>) => onSuggestError(e.detail);

      node.addEventListener('suggesterror', fn);
      return () => {
        node.removeEventListener('suggesterror', fn);
      };
    }, [ref.current, onSuggestError]);

    // Update onRetrieve.
    useEffect(() => {
      const node = ref.current;
      if (!node) return;

      if (!onRetrieve) return;

      const fn = (e: MapboxHTMLEvent<SearchBoxRetrieveResponse>) =>
        onRetrieve(e.detail);

      node.addEventListener('retrieve', fn);
      return () => {
        node.removeEventListener('retrieve', fn);
      };
    }, [ref.current, onRetrieve]);

    // Update onChange.
    useEffect(() => {
      const node = ref.current;
      if (!node) return;

      if (!onChange) return;

      const fn = (e: MapboxHTMLEvent<string>) => {
        if (e.target !== e.currentTarget) return; // ignore child input event
        onChange(e.detail);
      };

      node.addEventListener('input', fn);
      return () => {
        node.removeEventListener('input', fn);
      };
    }, [ref.current, onChange]);

    // Update accessToken.
    useEffect(() => {
      if (ref.current) ref.current.accessToken = accessToken;
    }, [ref.current, accessToken]);

    return <mapbox-search-box ref={ref} />;
  }
);
